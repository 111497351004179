@use '../style/vars';

$_side-padding: 16px;
$primary-color: vars.$vmr-green;

.vmr-option {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 0 16px;
  min-height: 48px;
  overflow: hidden;
  user-select: none;
  position: relative;
  align-items: center;
  letter-spacing: .006rem;
  justify-content: flex-start;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.vmr-list-item {
    &::before {
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      box-sizing: border-box;
    }
  }

  &.vmr-option-disabled {
    cursor: default;
    pointer-events: none;
  }

  &.vmr-option-multiple {
    .vmr-option-text {
      color: vars.$vmr-charcoal;
    }
  }

  .vmr-option-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
  }

  .vmr-option-text {
    overflow: hidden;
    margin-right: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .vmr-optgroup &:not(.vmr-option-multiple) {
    padding-left: $_side-padding;
  }

  .mat-pseudo-checkbox {
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: $primary-color;
  }

  .mat-pseudo-checkbox-minimal {
    flex-shrink: 0;
    margin-left: $_side-padding;
  }

  .mat-pseudo-checkbox-full {
    flex-shrink: 0;
    margin-right: $_side-padding;

    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      border-color: $primary-color;
      background-color: $primary-color;
    }
  }
}