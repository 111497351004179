@use 'sass:map';

// font-family
$vmr-fonts-base: 'Roboto', Arial, sans-serif;
$vmr-fonts-code: 'Roboto Mono', monospace;
$vmr-fonts-condensed: 'Roboto Condensed', sans-serif;

// font weight/size defaults
$font-size-base: 16px;
$vmr-font-condensed-weight-reg: 400;
$vmr-font-condensed-weight-bold: 700;

// VmrSidenav core styles
$vmr-sidenav-closed-width: 0px;
$vmr-sidenav-expanded-width: 190px;
$vmr-sidenav-collapsed-width: 58px;
$vmr-sidenav-transition-timing: 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

// VmrHeader core styles
$vmr-header-height: 50px;
$vmr-header-bg-color: black;

$vmr-colors: (
  'yellow': #ffc425,
  'green': #0a682f,
  'secondary-green': #06843c,
  'action-green': #009400,
  'secondary-yellow': #e1a907,
  'blue': #007fad,
  'bright-blue': #35b5e5,
  'purple': #4f4dd1,
  'charcoal': #272828,
  'black': #101010,
  'white': #ffffff,
  'action-red': #d60000,
  'orange': #dc7609,
  'info': #b3e4f7,
  'not-white': '#f7f7f7',
  'light-grey': #efefef,
  'concrete': #c4c6c8,
  'grey': #8d8d8d,
  'warm-grey': #707070,
  'slate': #5f6062,
  'dark-grey': #434343,
  'notification-blue': #0085B7,
  'brown': #856f1a,
  'green-light': #e6f6e6,
  'yellow-light': #faf5cd,
  'red-light': #FCEDED,
  'blue-light': #E9F4F8
);

// colors
// TODO: fleet uses #00703c for $vmr-green/$vmr-primary (need to sync apps)
$vmr-green: map.get($vmr-colors, 'green');
$vmr-yellow: map.get($vmr-colors, 'yellow');
$vmr-charcoal: map.get($vmr-colors, 'charcoal');

$vmr-black: map.get($vmr-colors, 'black');
$vmr-white: map.get($vmr-colors, 'white');

$vmr-secondary-yellow: map.get($vmr-colors, 'secondary-yellow');
$vmr-secondary-green: map.get($vmr-colors, 'secondary-green');

$vmr-not-white: map.get($vmr-colors, 'not-white');
$vmr-light-grey: map.get($vmr-colors, 'light-grey');
$vmr-concrete: map.get($vmr-colors, 'concrete');
$vmr-grey: map.get($vmr-colors, 'grey');
$vmr-warm-grey: map.get($vmr-colors, 'warm-grey');
$vmr-slate: map.get($vmr-colors, 'slate');
$vmr-dark-grey: map.get($vmr-colors, 'dark-grey');
$vmr-brown: map.get($vmr-colors, 'brown');

$vmr-action-green: map.get($vmr-colors, 'action-green');
$vmr-action-red: map.get($vmr-colors, 'action-red');
$vmr-notification-blue: map.get($vmr-colors, 'notification-blue');

$vmr-blue: map.get($vmr-colors, 'blue');
$vmr-bright-blue: map.get($vmr-colors, 'bright-blue');
$vmr-purple: map.get($vmr-colors, 'purple');
$vmr-brown: map.get($vmr-colors, 'brown');
$vmr-orange: map.get($vmr-colors, 'orange');

$vmr-green-light: map.get($vmr-colors, 'green-light');
$vmr-yellow-light: map.get($vmr-colors, 'yellow-light');
$vmr-red-light: map.get($vmr-colors, 'red-light');
$vmr-blue-light: map.get($vmr-colors, 'blue-light');

$vmr-primary: $vmr-green;
$vmr-secondary: $vmr-charcoal;
$vmr-basic: $vmr-black;
$vmr-accent: $vmr-yellow;
$vmr-warn: $vmr-action-red;

// Background of toasts/alerts for dark-mode
$vmr-dark-alert-bg: #181919;

// Density & shape
// container-shape is material terminology - we achieve this value in theme by configuring density with value of -1
$vmr-container-shape: 2px;

// Control height override (moving to MDC components, material updates heights from 40px => 48px)
$vmr-control-height: 40px;

// Button height override (material standard sets button heights to 36px)
$vmr-button-height: 40px;

// Control/button box-shadow - typically material controls are flat (as are buttons), but vermeer buttons use subtle shadow
$vmr-control-box-shadow: 1px 1px 3px rgba($vmr-black, 0.16);

// breakpoints
$grid-breakpoints: (
  'xxs': 0,
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1420px,
  'wide': 1600px,
  'xwide': 1900px
);

$screen-xxs: map.get($grid-breakpoints, 'xxs');
$screen-xs: map.get($grid-breakpoints, 'xs');
$screen-sm: map.get($grid-breakpoints, 'sm');
$screen-md: map.get($grid-breakpoints, 'md');
$screen-lg: map.get($grid-breakpoints, 'lg');
$screen-xl: map.get($grid-breakpoints, 'xl');
$screen-xxl: map.get($grid-breakpoints, 'xxl');
$screen-wide: map.get($grid-breakpoints, 'wide');
$screen-xwide: map.get($grid-breakpoints, 'xwide');